import { commonComponentTheme } from './common-component-theme';
import { lightBaseColors as baseColors } from './light-base-colors';
import { lightThemePrimitives as themePrimitives } from './light-theme-primitives';

export const lightComponentTheme: { [key: string]: string } = {
  ...commonComponentTheme,

  // Background
  backgroundPrimary: baseColors.gray50,
  backgroundSecondary: baseColors.gray200,
  backgroundTertiary: baseColors.gray700,
  backgroundInversePrimary: baseColors.gray800,
  backgroundInverseSecondary: baseColors.gray50,
  backgroundStark: baseColors.white,

  // Content
  contentStark: baseColors.black,
  contentPrimary: baseColors.gray800,
  contentSecondary: baseColors.gray500,
  contentTertiary: baseColors.gray400,
  contentInversePrimary: themePrimitives.primaryB,
  contentInverseSecondary: baseColors.gray600,
  contentInverseTertiary: baseColors.gray500,
  contentPrimaryMuted: baseColors.gray500,
  contentInactive: baseColors.gray400,

  // Borders
  borderPrimary: baseColors.gray300,
  borderSecondary: baseColors.gray300,

  // Buttons
  buttonPrimaryOutlineFill: 'transparent',
  buttonPrimaryOutlineText: baseColors.gray400,
  buttonPrimaryOutlineHover: baseColors.gray400,
  buttonPrimaryOutlineHoverText: baseColors.gray500,
  buttonPrimaryOutlineActive: themePrimitives.primary200,
  buttonPrimaryOutlineSelectedText: themePrimitives.black,
  buttonPrimaryOutlineSelectedFill: themePrimitives.primary200,

  buttonPrimaryFill: baseColors.gray600,
  buttonPrimaryText: baseColors.gray200,
  buttonPrimaryHover: baseColors.gray500,
  buttonPrimaryHoverText: baseColors.gray200,
  buttonPrimaryActive: themePrimitives.primary200,
  buttonPrimarySelectedText: themePrimitives.black,
  buttonPrimarySelectedFill: themePrimitives.white,
  buttonPrimarySpinnerForeground: themePrimitives.primary700,
  buttonPrimarySpinnerBackground: themePrimitives.primary300,

  buttonSecondaryFill: baseColors.white,
  buttonSecondaryText: baseColors.black,
  buttonSecondaryHover: baseColors.gray100,
  buttonSecondaryActive: themePrimitives.primary500,
  buttonSecondarySelectedText: themePrimitives.primary,
  buttonSecondarySelectedFill: themePrimitives.white,
  buttonSecondarySpinnerForeground: themePrimitives.white,
  buttonSecondarySpinnerBackground: themePrimitives.primary400,

  buttonTertiaryFill: 'transparent',
  buttonTertiaryText: baseColors.black,
  buttonTertiaryHover: baseColors.gray100,
  buttonTertiaryActive: themePrimitives.primary600,
  buttonTertiarySelectedText: themePrimitives.primary,
  buttonTertiarySelectedFill: themePrimitives.primary600,
  buttonTertiarySpinnerForeground: themePrimitives.primary50,
  buttonTertiarySpinnerBackground: themePrimitives.primary500,

  buttonMinimalFill: 'transparent',
  buttonMinimalText: baseColors.black,
  buttonMinimalHover: baseColors.gray100,
  buttonMinimalActive: themePrimitives.primary600,
  buttonMinimalSelectedText: themePrimitives.primary,
  buttonMinimalSelectedFill: themePrimitives.primary600,
  buttonMinimalSpinnerForeground: themePrimitives.primary50,
  buttonMinimalSpinnerBackground: themePrimitives.primary500,

  buttonDisabledFill: baseColors.gray400,
  buttonDisabledText: baseColors.white,
  buttonDisabledSpinnerForeground: themePrimitives.mono200,
  buttonDisabledSpinnerBackground: themePrimitives.mono400,

  buttonLinkText: baseColors.gray400,
  buttonLinkTextHover: baseColors.gray600,

  // Combobox
  comboboxListItemFocus: themePrimitives.mono600,
  comboboxListItemHover: themePrimitives.mono500,

  // Switch
  switchBackground: '#656767',

  // Input
  inputFont: baseColors.black,
  inputBorder: baseColors.white,
  inputBorderActive: themePrimitives.primaryA,
  inputFontActive: baseColors.black,
  inputFill: baseColors.white,
  inputFillActive: baseColors.white,
  inputFillError: themePrimitives.negative700,
  inputFillDisabled: themePrimitives.mono800,
  inputFillPositive: themePrimitives.positive700,
  inputTextDisabled: themePrimitives.mono500,
  inputBorderError: themePrimitives.negative400,
  inputBorderPositive: themePrimitives.positive400,
  inputEnhancerFill: themePrimitives.mono500,
  inputEnhancerFillDisabled: themePrimitives.mono700,
  inputEnhancerTextDisabled: themePrimitives.mono500,
  inputPlaceholder: themePrimitives.mono300,
  inputPlaceholderDisabled: themePrimitives.mono500,

  // Menu
  menuFill: baseColors.white,
  menuFillHover: baseColors.gray100,
  menuFontDefault: themePrimitives.gray800,
  menuFontDisabled: themePrimitives.mono400,
  menuFontHighlighted: themePrimitives.black,
  menuFontSelected: themePrimitives.black,

  // Table
  tableBackground: 'transparent',
  tableRowHoverBackground: themePrimitives.mono700,
  tableContent: baseColors.gray600,

  // Card
  cardPrimaryFill: baseColors.white,
  cardPrimaryHeaderColor: baseColors.black,
  cardPrimaryContentColor: baseColors.black,
  cardDividerColor: themePrimitives.primary500,

  // Tags
  outgoingTagBackground: baseColors.blue50,
  outgoingTagContent: baseColors.blue700,
  incomingTagBackground: baseColors.green50,
  incomingTagContent: baseColors.green700,

  // Sidebar
  sidebarBackground: '#202020',
  sidebarSelectedText: themePrimitives.white,
  sidebarUnselectedText: '#C2BFBF',

  // Door
  doorShippingBackground: themePrimitives.shipping,
  doorShippingColor: themePrimitives.white,
  doorReceivingBackground: themePrimitives.receiving,
  doorReceivingColor: themePrimitives.white,
  doorNeutralBackground: themePrimitives.mono600,
  doorNeutralColor: themePrimitives.white,
  doorUnusedBackground: themePrimitives.mono100,
  doorUnusedColor: themePrimitives.white,

  // Shipping Status Badge
  doorStatusShippingBackground: themePrimitives.shippingHighlight,
  doorStatusShippingColor: themePrimitives.shippingDarker,
  doorStatusReceivingBackground: themePrimitives.receivingHighlight,
  doorStatusReceivingColor: themePrimitives.receivingDarker,
  doorStatusDetentionBackground: baseColors.red50,
  doorStatusDetentionColor: baseColors.red500,

  // Tabs
  tabsBar: baseColors.gray300,
  tabsBarHover: baseColors.gray400,
  tabsBarActive: themePrimitives.black,
  tabsFont: baseColors.gray500,
  tabsFontHover: baseColors.gray600,
  tabsFontActive: themePrimitives.black,

  // VerticalTimeline
  verticalTimelineColor: baseColors.gray300,
  verticalTimelineBackgroundPrimary: baseColors.white,
  verticalTimelineBackgroundSecondary: themePrimitives.mutedBackgroundPrimary,

  // horizontalTimeline
  horizontalTimelineBar: baseColors.gray500,

  // Slider
  sliderBar: themePrimitives.mono600,
  sliderButton: themePrimitives.mono400,

  // Pagination buttons
  paginationButtonOutline: baseColors.gray400,
  paginationButtonOutlineHover: baseColors.gray500,
  paginationButtonOutlineDisabled: baseColors.gray200,
};
