import { gql, useQuery } from '@apollo/client';
import { useFacility, useLogger } from '@kargo/context';
import type Logger from '@kargo/context/logging/types';
import type { GetFeatureFlagsQuery } from 'generated/graphql';
import { createContext, useState, useEffect, useMemo, useContext } from 'react';

const GET_FEATURE_FLAGS = gql`
  query GetFeatureFlags {
    me {
      featureFlags {
        featureId
        enabledVariantId
        scopes {
          businessId
          enabledVariantId
        }
      }
    }
  }
`;

type BusinessFeatureFlag = {
  enabledVariantId: string;
  businessId: number;
};

type FeatureFlag = {
  featureId: string;
  enabledVariantId: string;
  scopes: BusinessFeatureFlag[];
};

type ContextValue = { featureFlags: Array<FeatureFlag> };

const FeatureFlagContext = createContext<ContextValue>({
  featureFlags: [],
});

const FLAGS_VERSION = '1.0';

const getBusinessFromFeatureScopes = (
  businessId: number | undefined,
  featureFlag: FeatureFlag | undefined,
) => {
  return businessId !== undefined
    ? featureFlag?.scopes?.find(
        (businessFeature) => businessFeature.businessId === businessId,
      )
    : undefined;
};

const isFeatureEnabled = (
  enableValues: string[],
  businessFeature: BusinessFeatureFlag | undefined,
  featureFlag: FeatureFlag | undefined,
) => {
  if (businessFeature) {
    return enableValues.includes(businessFeature?.enabledVariantId);
  } else if (featureFlag) {
    return enableValues.includes(featureFlag.enabledVariantId);
  }

  return false;
};

const useFeatureFlags = (logger: Logger) => {
  const CACHE_ID = `FEATURE_FLAGS_${FLAGS_VERSION}`;

  const { data, error } = useQuery<GetFeatureFlagsQuery>(GET_FEATURE_FLAGS);
  const [features, setFeatures] = useState<Array<FeatureFlag>>(() => {
    const cache = localStorage.getItem(CACHE_ID);
    return cache ? JSON.parse(cache) : [];
  });

  useEffect(() => {
    if (logger && error) {
      logger.error('feature flag load failure', { error });
      setFeatures([]);
      return;
    }
    if (!data?.me) return;

    const features = data.me.featureFlags;
    setFeatures(features);
    localStorage.setItem(CACHE_ID, JSON.stringify(features));
  }, [data, error, logger]);

  return features;
};

type Props = { children: React.ReactNode };

const FeatureFlagsProvider = ({ children }: Props) => {
  const logger = useLogger();
  const featureFlags = useFeatureFlags(logger);

  return (
    <FeatureFlagContext.Provider value={{ featureFlags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

const useFeatureFlagEnabled = (input: {
  featureId: string;
  enabledVariants: string | Array<string>;
}) => {
  const { featureId, enabledVariants } = input;
  const { facility } = useFacility();

  const enableValues = useMemo(() => {
    return typeof enabledVariants === 'string'
      ? [enabledVariants]
      : enabledVariants;
  }, [enabledVariants]);

  const [enabled, setEnabled] = useState(false);
  const { featureFlags } = useContext(FeatureFlagContext);

  useEffect(() => {
    const featureFlag = featureFlags.find(
      (flag) => flag.featureId === featureId,
    );

    const businessFeature = getBusinessFromFeatureScopes(
      facility?.businessId ||
        Number(sessionStorage.getItem('selectedBusinessId')),
      featureFlag,
    );

    setEnabled(isFeatureEnabled(enableValues, businessFeature, featureFlag));
  }, [featureFlags, enableValues, facility]);

  return enabled;
};

export { FeatureFlagsProvider, useFeatureFlagEnabled };
