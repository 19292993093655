import type { AppState } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import kargoTheme from '@components/kargo-ui/theme/mui';
import { LayersProvider, logger, LoggerProvider } from '@kargo/context';
import { ThemeProvider } from '@mui/material/styles';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import type { NextPage } from 'next/types';
import { useCallback } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../styles/globals.css';

export const HOSTNAME = process.env.NEXT_PUBLIC_VERCEL_URL
  ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
  : 'http://localhost:3000';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => JSX.Element;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page);

  const router = useRouter();
  const onRedirectCallback = useCallback((appState?: AppState) => {
    router.replace(appState?.returnTo || '/');
  }, []);

  return (
    <Auth0Provider
      domain='mykargo.us.auth0.com'
      clientId='gQhQrNR1TCzKX51533wupTj3DE7TtDDP'
      redirectUri={HOSTNAME}
      audience='https://api.kargo.zone/graphql'
      scope='openid profile email'
      cacheLocation='localstorage'
      onRedirectCallback={onRedirectCallback}
    >
      <Script
        src='https://documentcloud.adobe.com/view-sdk/main.js'
        strategy='lazyOnload'
      />
      <LoggerProvider logger={logger}>
        <ThemeProvider theme={kargoTheme}>
          <LayersProvider>
            {getLayout(<Component {...pageProps} />)}
          </LayersProvider>
        </ThemeProvider>
      </LoggerProvider>
    </Auth0Provider>
  );
}

export default MyApp;
