import { createContext, useState, useCallback, useContext } from 'react';
import { v4 as uuid } from 'uuid';

type LayerIDs = Array<string>;
type Props = { children: React.ReactNode; startAt?: number };

const LayerContext = createContext<{
  createLayer: (() => string) | undefined;
  removeLayer: ((id: string) => void) | undefined;
  layerIDs: LayerIDs;
  startAt: number;
}>({
  layerIDs: [],
  createLayer: undefined,
  removeLayer: undefined,
  startAt: 1,
});

const LayersProvider = ({ children, startAt = 1 }: Props) => {
  const [layerIDs, setLayerIDs] = useState<Array<string>>([]);

  const createLayer = useCallback(() => {
    const layerID = uuid();
    setLayerIDs([...layerIDs, layerID]);
    return layerID;
  }, []);

  const removeLayer = useCallback((id: string) => {
    setLayerIDs(layerIDs.filter((i) => i !== id));
  }, []);

  return (
    <LayerContext.Provider
      value={{ layerIDs, createLayer, removeLayer, startAt }}
    >
      {children}
    </LayerContext.Provider>
  );
};

const useLayers = () => {
  const { createLayer, removeLayer, layerIDs, startAt } =
    useContext(LayerContext);
  return { createLayer, removeLayer, layerIDs, startAt };
};

export { LayersProvider, useLayers };
