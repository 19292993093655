import { useLogger } from '@kargo/context';
import { createContext, useEffect, useContext } from 'react';

export type Facility = {
  id: number;
  slug: string;
  businessId: number;
  businessSlug: string;
  timezoneName: string;
  timezoneAbbr: string;
};

const FacilityContext = createContext<{
  facility: Facility | null;
  setFacility: Function;
}>({
  facility: null,
  setFacility: () => {},
});

const useFacilityLogging = (facility: Facility | null) => {
  const logger = useLogger();

  useEffect(() => {
    if (!logger) return;
    logger.debug('facility selected', { facility });
  }, [logger, facility]);
};

type Props = {
  facility: Facility | null;
  children: React.ReactNode;
  setFacility: Function;
};

const FacilityProvider = ({ facility, setFacility, children }: Props) => {
  useFacilityLogging(facility);

  return (
    <FacilityContext.Provider
      value={{
        facility,
        setFacility,
      }}
    >
      {children}
    </FacilityContext.Provider>
  );
};

const useFacility = () => {
  const { facility, setFacility } = useContext(FacilityContext);
  return { facility, setFacility };
};

export { FacilityProvider, useFacility, FacilityContext };
