import { datadogLogs } from '@datadog/browser-logs';
import { v4 as uuid } from 'uuid';
import type Logger from './types';

const DATADOG_CLIENT_TOKEN = 'pubb2887cd48c45e974047e98d9a47b55f1';
const LOAD_ID = uuid();

type LOG_LEVEL = 'debug' | 'info' | 'warn' | 'error';

datadogLogs.init({
  clientToken: DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: 'kargo-dash',
  env: process.env.NODE_ENV,
});

datadogLogs.addLoggerGlobalContext('loadId', LOAD_ID);

const localLog = (level: LOG_LEVEL, message: string, context: {}) => {
  if (console[level]) {
    console[level](message, context);
  } else {
    console.log(`[${level}]: ${message}`, context);
  }
};

const remoteLog = (level: LOG_LEVEL, message: string, context: {}) => {
  datadogLogs.logger[level](message, context);
};

const _doLogging = (level: LOG_LEVEL, message: string, context: {}) => {
  localLog(level, message, context);
  remoteLog(level, message, context);
};

const logger: Logger = {
  debug: _doLogging.bind(this, 'debug'),
  info: _doLogging.bind(this, 'info'),
  warn: _doLogging.bind(this, 'warn'),
  error: _doLogging.bind(this, 'error'),
};

export default logger;
