import { lightTheme } from '@components/kargo-ui/theme/light-theme';
import { createTheme } from '@mui/material/styles';

const MUItheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
      light: '#FFFFFF',
      dark: '#3E3E3E',
    },
  },
  typography: {
    fontFamily: [
      'Work sans',
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
    ].join(','),
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

const kargoTheme = Object.assign({}, lightTheme, MUItheme);
export default kargoTheme;
