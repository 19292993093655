import type { KargoTheme } from '@kargo/types/theming';
import { avatarColors } from './avatar-colors';
import { borders } from './borders';
import { lightBaseColors } from './light-base-colors';
import { lightComponentTheme } from './light-component-theme';
import { lightThemePrimitives } from './light-theme-primitives';
import { sizing } from './sizing';
import { typography } from './typography';

export const lightTheme: KargoTheme = {
  colors: {
    ...avatarColors,
    ...lightBaseColors,
    ...lightThemePrimitives,
    ...lightComponentTheme,
  },
  sizing,
  borders,
  typography,
  shadows: [],
};
