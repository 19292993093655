import { createContext, useContext, useMemo } from 'react';

const TokenContext = createContext<{
  token: string;
}>({
  token: '',
});

const useToken = () => {
  const { token } = useContext(TokenContext);
  const permissions: string[] = useMemo(() => {
    if (token === '') {
      return [];
    }
    const pst = token
      .split('.')
      .map((a) => atob(a.replace(/_/g, '/').replace(/-/g, '+')))[1];
    return JSON.parse(pst)['permissions'];
  }, [token]);
  return { token: token, permissions: permissions };
};

export { TokenContext, useToken };
