type Sizing = { [key: string]: string };

const sizing: Sizing = (() => {
  const scales: Sizing = {};
  for (let i = 0; i < 48; i++) {
    scales[`scale${i * 100}`] = `${i * 2}px`;
  }
  return scales;
})();

export { sizing };
