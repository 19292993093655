export const avatarColors: { [key: string]: string } = {
  midnight: '#121266',
  persian: '#3A3AD1',
  danube: '#0082C9',
  tradewind: '#01AAAD',
  jade: '#00A85E',
  conifer: '#A9CE36',
  portica: '#FAE84E',
  saffron: '#F4C042',
  jaffa: '#EE9545',
};
