const titleTypography = {
  titleSmall: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
};

export const typography = {
  ...titleTypography,
};
